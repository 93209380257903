import React from 'react';
import PropTypes from 'prop-types';

export const About = props => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {' '}
            <img src="img/about.jpg" className="img-responsive" alt="" />{' '}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph1 : 'loading...'}</p>
              <h3>What We Do:</h3>
              <p>{props.data ? props.data.paragraph2 : 'loading...'}</p>
              <div className="list-style">
                <div className="col-lg-12 col-md-8 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                  <br />
                </div>
              </div>
              <h3>Why Choose Us:</h3>
              <p>{props.data ? props.data.paragraph3 : 'loading...'}</p>
              <p>{props.data ? props.data.paragraph4 : 'loading...'}</p>
              <h3>Our Promise:</h3>
              <p>{props.data ? props.data.paragraph5 : 'loading...'}</p>
              <p>{props.data ? props.data.paragraph6 : 'loading...'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  data: PropTypes.shape({
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired,
    Why: PropTypes.arrayOf(PropTypes.string).isRequired,
    paragraph3: PropTypes.string.isRequired,
    paragraph4: PropTypes.string.isRequired,
    paragraph5: PropTypes.string.isRequired,
    paragraph6: PropTypes.string.isRequired,
  }).isRequired,
};