import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Favicon from 'react-favicon';
import { register, unregister } from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Favicon url="../img/logos/favicon.png" iconSize="9" />
    <App />
  </React.StrictMode>
);

// Register service worker
register({});

// Unregister service worker (if needed)
unregister();