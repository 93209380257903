import React from 'react';
import PropTypes from 'prop-types';

export const Header = props => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-8 text-center intro-text">
                <h1 className="text-center">
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p className="text-center">
                  {props.data ? props.data.paragraph : 'Loading'}
                </p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  Explore More
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
  }).isRequired,
};
